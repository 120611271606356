.chatbox1{

}
.close-icon{
    display: none;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
    background-color: #bb6bc1;
    color: white;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    /* width: 250px; */
    border-radius: 25px;
    font-size: large;
    z-index: 888;
  }
  
  /* The popup chat - hidden by default */
  .chat-popup {
    display: none;
    position: fixed;
    bottom: 75px;
    right: 18px;
    padding: 5px 10px;
    /* border: 2px solid #f1f1f1; */
    z-index: 9999;
    border-radius: 20px;

  }
  
  /* Add styles to the form container */
  .chat-container {
    /* max-width: 300px;
    padding: 10px;
    background-color: white; */
    display: flex;
    flex-direction: column;
  }
  
  /* Full-width textarea */
  .chat-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }
  
  /* When the textarea gets focus, do something */
  .chat-container textarea:focus {
    background-color: #ddd;
    outline: none;
    
  }
  
  /* Set a style for the submit/send button */
  .chat-container .chat-btn {
     background-color: #0f928d;
     font-size: large;
     color: #fff;
     padding: 10px 10px; 
     border-radius: 50%;
     cursor: pointer;
    opacity: 0.8;

   /* color: black;
    
    border: none;
    cursor: pointer; */
    /* width: 100%; */
    /* margin-bottom:10px; */
    /* opacity: 0.8;
    font-size: large; */
  }
  
  /* Add a red background color to the cancel button */
  .chat-container .cancel {
    background-color: rgb(250, 250, 250);
  }
  
  /* Add some hover effects to buttons */
  .chat-container .btn:hover, .open-button:hover {
    opacity: 1;
  }
.header-bottom {
    padding: 3px 0;
    border-top:2px solid #f26324;
    box-shadow: 2px 4px 8px rgba(51,51,51,.25);
}

.logoimg{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}


.header-actions{
    white-space: nowrap;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px)
{
.main-menu ul li+li {
    margin-left: 24px;
}
}
.main-menu > ul > li:nth-of-type(1n+10){
    display:none;
 }
.main-menu ul
{
    display:flex;
    /* flex-wrap:wrap; */
    margin-bottom:0;
    padding-left:0;
    list-style:none;
    max-width: 100%;
    /* overflow-x: hidden; */
    white-space:nowrap;
}

  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        /* max-width: 90px !important;
        height: 55px; */
        /* width: 80px; */
    }
  }
@media (max-width: 600px ) {
   
}
.bg-black{
    /* background:#277373; */
    background: #595758;
    /* background-color: ; */
}
.main-menu ul li.dropdown ul li a{
    height: auto;
    
}
.main-menu ul li.active>a, .main-menu ul li:hover>a{
    color:#595758;
}
.main-menu ul li a{
    font-size: 17px;
    text-transform: capitalize !important;
     /* font-weight: 600; */
}
.main-menu ul li.dropdown ul li a{
    font-weight: normal;
}
.header-top-massege p{
    color:#fff;
}
.header-top{
    padding:1px 1px;
}
.header-action-btn a i::before{
   /* margin-top: 5px; */
}
.header-actions .header-action-btn .header-action-num{
    background-color: #bb6bc1;
}
.btn-warning {
    color: #fff;
    background-color: #bb6bc1;
    border-color: #fff;
}
@media only screen and (max-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        /* max-width: 82px !important; */
        /* width: auto; */
        height: 40px;
    }
    .custom-search{
        display: none;
    }
    
  }
  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        /* max-width: 60px !important;
        height: 40px; */
        /* width: 116px; */
        /* width: 156px; */
        height: 60px;
    }
    .custom-search{
        padding-top: 10px; 
        padding-bottom: 5px;
        padding-left: 100px;
        /* padding-right: 100px; */
    }
    .custom-search select{
        font-size: medium;
        background-color: #e9ecef;
    }
    .header_account_list{
        display: none;
    }
  }
@media (max-width: 600px ) {
   
}
.main-menu ul li.dropdown ul.sub-menu{
    z-index: 25 !important;
    max-width:fit-content;
    padding-right: 10px;
}

.main-menu ul li.active>a, .main-menu ul li:hover>a {
    /* color: #6c0a0d!important; */
    /* color: #fff!important; */
    scale: 1.1;
}
.ui-autocomplete {
    max-height: 300px;
    max-width:fit-content;
    word-wrap: break-word;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
    border:1px solid #222;
    position:absolute;
    
  }
  ul.ui-autocomplete.ui-menu{
    width:400px;
    
}
li.ui-menu-item .ui-menu-item-wrapper{
    border-bottom:0.1px solid #a6a3a32e;
}
.btn-warning:hover {
    color: #fff;
    background-color: #bb6bc1;
    border-color: #bb6bc1;
}
.sub-menu li a{
    color:#000;
}
.sub-menu li a:hover{
    color:#000;
}

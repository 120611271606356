.bgCard{
    background-color: #ffffffbf !important;
}
.white{
    color:#fff;
}
.black{
    color:#000;
}
body{
    /* background-image: url(../assets/images/bg-3.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

      /* background-image: url('../assets/images/bg-2.jpg'); */
         /* background: #fff; */
    /* color:#7b0000; */
    /* background-position: center; */
    /* background-repeat: no-repeat;  */
    /* background-size: cover; */
    

   
    
}
.container, .container-lg, .container-md, .container-sm, .container-xl {
     width: 98%!important; 
     /* max-width:90%!important */
}
@media only screen and (min-width: 650px)
{
    .container-sm {
        max-width: 70%!important;
    }
}

.row {
    --bs-gutter-x: 14px;
}
.wrapper{
    /* opacity: 999; */
    /* background: #dcd7d747; */
}
section{
     /* background: #ffffffb0;  */
}
.green{
   
    /* background: #0f928d; */
    background: #874CCC;
    
}
.green-dark{
    
    /* background: #3e3b3c !important; */
    background: #131921!important;
}
.btn-primary {
    background-color: #bb6bc1;
}
.main-menu.manu-color-white ul li a{
    color:#fff;
}
.category-wrapper .category-inner{
    border-radius: 20%;
}

.banner-3 img{height:327px; }

.border-box{
    box-shadow: 0 0 3px 0px rgb(2 2 2 / 26%);
    -webkit-box-shadow: 0 0 3px 0px rgb(2 2 2 / 26%);
    /* box-shadow: 2px 5px 13px 2px #6c757d; */
    /* box-shadow: 0px 0px 0px 1px #6c757d5e;
    border-radius: 20px; */
    padding: 20px;
    /* margin: 5px; */
     margin-top: 5px; 
    margin-bottom: 5px;
    /* background: #FFFFFF;  */
}
.border-box-2{
    text-align: center;
    /* border-radius: 10px; */
    background: #fff;
    /* box-shadow: 0px 2px 15px rgb(0 0 0 / 10%); */
    /* box-shadow: 2px 4px 11px rgb(245 134 85 / 65%); */
    box-shadow: 2px 2px 2px rgb(245 134 85 /5%);
    
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.bg-themecolor{
  background-color: #0c6942;
  color:#fff;
}
.bg-themecolor a h4{
    color:#fff !important;
}

.heading .title{
    color: #03633b;
    
}
.heading .page__title-bar{
    display: inline-block;
}
a {
    color:#271304;

}
.btn-primary {
    color: #fff;
    /* background-color: #e2434a !important; */
    border-color: #000 !important;
    border: 0;
    transition: all .3s ease 0s;
    border-radius: 23px;
}
.btn-secondary{
    border-color: #000 !important;
    border: 0;
    transition: all .3s ease 0s;
    border-radius: 23px;
}
.btn-danger{
    color: #fff;
    background-color: #dc3545;
    border-color: #000 !important;
    border: 0;
    transition: all .3s ease 0s;
    border-radius: 23px;

}
.btn-primary_two{
    color: #fff !important;
    background-color: #007bff;
    border-color: #000 !important;
    border: 0;
    transition: all .3s ease 0s;
    border-radius: 23px;
}
h2{
    /* color: #006338; */
}
.title.h3{
    color: #006338 !important;
    font-size: 1.3rem !important;
}
.h3 h3{
    font-size: calc(1rem + .6vw) !important;
}
.submit{
    /* background-color:#4c9090 !important; */
}

.heading>.title:after{
    content: "";
    background: no-repeat;
    /* background-image: url(../assets/images/border-bottom.svg); */
    /* width: 100px !important; */
    width: calc(100%);
    height: 2px !important;
    background-color: #992036;
    position:relative;
    /* left: 0;
    right: 0; */
    margin: auto;
    display: block;
    text-align: left;
    float:left;
     /* bottom: -10px; */
}
.slider-nav-style-1 .swiper-buttons .swiper-button-next, .slider-nav-style-1 .swiper-buttons .swiper-button-prev{
background-color: #ec802e;

}
.pt-50px{
padding-top: 40px;
}
.pb-50px{
    padding-bottom: 40px;

}
.pt-70px {
    padding-top: 70px;
}
.pb-70px {
    padding-bottom: 70px;
}

.product:hover .thumb .image img {
    transform: scale(1.1);
}
.product .thumb .image img{
    /* height: 100%; */
    /* width: 100%; */
    max-width: 100%;
    border-radius: 10px;
}
.small_container{
    max-width: 80%; margin: auto;
}
/* why us */
.flex-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}
.mbdhimg{
    top:20px;
   width: 100%;
   height: 20%;
   text-align: center;
}
#headimg span{
    padding-left: 5px;
}
#headimg{
    width: 40%;
    /* margin-top:80px; */
    /* margin-left: 30%; */
    margin:auto;
    background-color:#276130;
    color: white;
    font-size: 25px;
    font-family:'Poppins',sans-serif ;   
    align-self: center; 
}
#txtdata{
    margin-top: 20px;
    margin-left: 20%;
    color:#276130;
    font-size: 24px;
    font-family:"Lucida Console", "Courier New", monospace;
    width: 60%;
}
.imagediv{
   
      width:100%;
    height: 30%;
    margin:0;
    padding-left:30px;       
    padding-right: 30px; 
}
#imageid{
    align-self:flex-start;   
    padding-left:10px;
    padding-right: 10px;
    margin: 0; 
     width: max-content;
}
#imageid img{
    max-height: 83px;
    width: auto;
}
#btmtxt{
    font-size: 15px;
    color:#081a0a;  
    overflow: wrap; 
    font-family: 'Open Sans',sans-serif;
    padding-left: 5px;
    padding-right: 5px;
    }
.btndesign{
    margin-top:50px;
    background-color:#276130;
    color: white;
    font-size: 30px;
    font-weight: 600;
    padding: 0 40px 0 40px;
    font-family:'Open Sans', sans-serif;
}
#headimg span{
    font-family: 'Open Sans', sans-serif;font-size: 30px;font-weight: 600;
}
.product .thumb .add-to-cart{
    background-color: #4c9090;
    color:#FFFFFF;
}
.footer-area .footer-top{
    padding-bottom: 30px;
}

:root{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
body{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  
    /* font-family: "Georgia",sans-serif !important; */
    font-family: "Roboto", sans-serif;
  
}
.title{
   font-weight: 500 !important;
   text-transform: capitalize !important;
}
.header-top-massege p{
    font-family: "Georgia",sans-serif !important;
}
.header-top-set-lan-curr {
    font-family: "Georgia",sans-serif !important;
}
.copy-text{
    font-family: "Georgia",sans-serif !important;
}
.category-wrapper .category-inner .category-single-item .title{
    font-family: "Georgia",sans-serif !important;
    font-weight: 500 !important;
}
.footer-herading{
    text-transform: capitalize !important;
    /* text-transform:uppercase !important; */
    font-size: 18px !important;
}
.footer-area .footer-top .single-wedge .footer-links .li{
    margin-bottom: 1px !important;
}
.contact-form-style a{
    color: #14955f !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input[type=checkbox]{
    height: 15px;
    width: min-content;
    margin-right: 5px;
}
.linkclass{
    color: #14955f !important;
    font-weight: 700;

}
.footer-herading a{
    color:#fff !important;
}
td{
    padding: 5px;
}
h1,h2,h3,h4,h5{
    /* color:#006338; */
}
.product .content .title a{
    overflow: inherit;
    white-space:pre-wrap;
}
a:hover{
    color:#ff7004;
    /* color:#000; */
    /* color:#ddd6d6; */
    
}
table>a:hover{
    color:#074946;
}
.main-menu ul li a{
    line-height: 45px;
}
.form-control{

    padding:6px 12px 6px 12px;
    /* background-color:#e8e8e8; */
    /* min-height: 40px; */
    border-radius:4px;
    }
    
    input{
    /* height:40px; */
    }
.dia_cart {
        padding: 20px;
    }  
    .card {
        box-shadow: 0 6px 12px 0 rgba(0,0,0,.2);
    }
    .form-control-label {
        clear: both;
        float: left;
        margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    }
@media only screen and (max-width: 479px)
{
    /* .col-xs-6 {
        width: 50%;
    }
    .col-sm-4 {
        width: 33.33%;
    } */

}
.slider-dot-style-1.dot-color-white .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background-color: #bb6bc1;
    border-color:#bb6bc1;
}
.slider-nav-style-1 .swiper-buttons .swiper-button-next, .slider-nav-style-1 .swiper-buttons .swiper-button-prev{
    background-color:#bb6bc1;
}
